import React, { useState } from 'react';
import styled from 'styled-components';
import { usePDF } from 'react-to-pdf';

interface DetailsModalContentProps {
  analysisResults: any;
}

const DetailsModalContent: React.FC<DetailsModalContentProps> = ({ analysisResults }) => {
  const { toPDF, targetRef } = usePDF({filename: 'detailed_analysis_results.pdf'});
  const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const hasData = analysisResults && Object.keys(analysisResults).length > 0;

  return (
    <ModalContent>
      <ModalTitle>Detailed Analysis Results</ModalTitle>
      {hasData && (
        <DownloadButtonWrapper>
          <DownloadButton onClick={() => toPDF()}>Download as PDF</DownloadButton>
        </DownloadButtonWrapper>
      )}
      <ContentWrapper ref={targetRef}>
        {hasData ? (
          Object.entries(analysisResults).map(([mutation, treatments]: [string, any]) => (
            <MutationSection key={mutation}>
              <MutationTitle>{mutation}</MutationTitle>
              <TableWrapper>
                {isMobileView ? (
                  <MobileTable>
                    {Object.entries(treatments).map(([treatment, data]: [string, any]) =>
                      data.map((item: any, index: number) => (
                        <MobileTableCard key={`${treatment}-${index}`}>
                          <MobileTableRow>
                            <MobileTableHeader>Treatment</MobileTableHeader>
                            <MobileTableCell>{treatment}</MobileTableCell>
                          </MobileTableRow>
                          <MobileTableRow>
                            <MobileTableHeader>Link</MobileTableHeader>
                            <MobileTableCell>
                              {item.research_metadata?.url && (
                                <a href={item.research_metadata.url} target="_blank" rel="noopener noreferrer">
                                  Link
                                </a>
                              )}
                            </MobileTableCell>
                          </MobileTableRow>
                          <MobileTableRow>
                            <MobileTableHeader>Cohort Size</MobileTableHeader>
                            <MobileTableCell>{item.cohort_num}</MobileTableCell>
                          </MobileTableRow>
                          <MobileTableSection>General Response</MobileTableSection>
                          <MobileTableRow>
                            <MobileTableHeader>PFS</MobileTableHeader>
                            <MobileTableCell>{item.general_response.PFS}</MobileTableCell>
                          </MobileTableRow>
                          <MobileTableRow>
                            <MobileTableHeader>ORR</MobileTableHeader>
                            <MobileTableCell>{item.general_response.ORR}</MobileTableCell>
                          </MobileTableRow>
                          <MobileTableRow>
                            <MobileTableHeader>OS</MobileTableHeader>
                            <MobileTableCell>{item.general_response.OS}</MobileTableCell>
                          </MobileTableRow>
                          <MobileTableRow>
                            <MobileTableHeader>Notes</MobileTableHeader>
                            <MobileTableCell>{item.general_response['important note']}</MobileTableCell>
                          </MobileTableRow>
                          <MobileTableSection>CNS Response</MobileTableSection>
                          <MobileTableRow>
                            <MobileTableHeader>PFS</MobileTableHeader>
                            <MobileTableCell>{item.cns_response.PFS}</MobileTableCell>
                          </MobileTableRow>
                          <MobileTableRow>
                            <MobileTableHeader>ORR</MobileTableHeader>
                            <MobileTableCell>{item.cns_response.ORR}</MobileTableCell>
                          </MobileTableRow>
                          <MobileTableRow>
                            <MobileTableHeader>Notes</MobileTableHeader>
                            <MobileTableCell>{item.cns_response['important note']}</MobileTableCell>
                          </MobileTableRow>
                        </MobileTableCard>
                      ))
                    )}
                  </MobileTable>
                ) : (
                  <Table>
                    <thead>
                      <tr>
                        <th rowSpan={2}>Treatment</th>
                        <th rowSpan={2}>Link</th>
                        <th rowSpan={2}>Cohort Size</th>
                        <th colSpan={4}>General Response</th>
                        <th colSpan={3}>CNS Response</th>
                      </tr>
                      <tr>
                        <th>PFS</th>
                        <th>ORR</th>
                        <th>OS</th>
                        <th>Notes</th>
                        <th>PFS</th>
                        <th>ORR</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(treatments).map(([treatment, data]: [string, any]) =>
                        data.map((item: any, index: number) => (
                          <tr key={`${treatment}-${index}`}>
                            {index === 0 && <TreatmentCell rowSpan={data.length}>{treatment}</TreatmentCell>}
                            <td>
                              {item.research_metadata?.url && (
                                <a href={item.research_metadata.url} target="_blank" rel="noopener noreferrer">
                                  Link
                                </a>
                              )}
                            </td>
                            <td>{item.cohort_num}</td>
                            <td>{item.general_response.PFS}</td>
                            <td>{item.general_response.ORR}</td>
                            <td>{item.general_response.OS}</td>
                            <td>{item.general_response['important note']}</td>
                            <td>{item.cns_response.PFS}</td>
                            <td>{item.cns_response.ORR}</td>
                            <td>{item.cns_response['important note']}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                )}
              </TableWrapper>
            </MutationSection>
          ))
        ) : (
          <NoDataMessage>No detailed analysis results found.</NoDataMessage>
        )}
      </ContentWrapper>
    </ModalContent>
  );
};

const ModalContent = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const ModalTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const MutationSection = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

const MutationTitle = styled.h3`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  table-layout: fixed;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    word-break: normal;
    overflow-wrap: normal;
    white-space: normal;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: center;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TreatmentCell = styled.td`
  vertical-align: top;
  font-weight: bold;
`;

const MobileTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MobileTableCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
`;

const MobileTableRow = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }
`;

const MobileTableHeader = styled.div`
  flex: 1;
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 8px;
`;

const MobileTableCell = styled.div`
  flex: 2;
  padding: 8px;
`;

const MobileTableSection = styled.div`
  background-color: #e0e0e0;
  font-weight: bold;
  padding: 8px;
  text-align: center;
`;

const DownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const DownloadButton = styled.button`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
`;

const NoDataMessage = styled.p`
  text-align: center;
  font-style: italic;
  color: #666;
`;

export default DetailsModalContent;