import React, { useState } from 'react';
import styled from 'styled-components';
import { updateUser } from '../../services/api';
import { getToken } from '../../utils/authUtils';

interface ProfileFormProps {
  userData: any;
  onSubmit: (updatedData: any) => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ userData, onSubmit }) => {
  const [profession, setProfession] = useState(userData.profession);
  const [organization, setOrganization] = useState(userData.organization);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const token = getToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const updatedData = { profession, organization };
      const result = await updateUser(updatedData);
      onSubmit(result);
    } catch (err) {
      setError('Failed to update profile. Please try again.');
      console.error('Error updating profile:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Title>My Profile</Title>
      <InputGroup>
        <Label>Email:</Label>
        <Input type="email" value={userData.email} disabled />
      </InputGroup>
      <InputGroup>
        <Label>Name:</Label>
        <Input type="text" value={userData.name} disabled />
      </InputGroup>
      <InputGroup>
        <Label>Profession:</Label>
        <Input
          type="text"
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
        />
      </InputGroup>
      <InputGroup>
        <Label>Organization:</Label>
        <Input
          type="text"
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
        />
      </InputGroup>
      <InputGroup>
        <Label>Registered on:</Label>
        <Input
          type="text"
          value={new Date(userData.createdAt).toISOString().split('T')[0]}
          disabled
        />
      </InputGroup>
      <InputGroup>
        <Label>Remaining credits:</Label>
        <Input
          type="text"
          value={userData.numOfPermittedRunning}
          disabled
        />
      </InputGroup>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <SubmitButtonContainer>
        <SubmitButton type="submit" disabled={isLoading}>
          {isLoading ? 'Updating...' : 'Update Profile'}
        </SubmitButton>
      </SubmitButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-sizing: border-box;
`;

const Title = styled.h2`
  margin-bottom: 15px;
  text-align: center;
  color: #333;
  font-size: 1.5em;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 3px;
  font-weight: bold;
  color: #555;
  font-size: 0.9em;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9em;
  width: 100%;
  box-sizing: border-box;

  &:disabled {
    background-color: #f0f0f0;
    color: #777;
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
`;

const SubmitButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
  text-align: center;
`;

export default ProfileForm;