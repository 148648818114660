import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface GraphResultsModalContentProps {
  finalResultsGraph?: {
    [mutation: string]: {
      [treatment: string]: {
        general_pfs: number | null;
        general_orr: number | null;
        cns_pfs: number | null;
        cns_orr: number | null;
      };
    };
  };
}

const GraphResultsModalContent: React.FC<GraphResultsModalContentProps> = ({ finalResultsGraph }) => {
  const hasData = finalResultsGraph && Object.keys(finalResultsGraph).length > 0;
  const mutations = hasData ? Object.keys(finalResultsGraph) : [];
  const [selectedMutation, setSelectedMutation] = useState<string>(mutations[0] || '');
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const prepareChartData = useCallback((type: 'ORR' | 'PFS', mutation: string) => {
    if (!finalResultsGraph || !finalResultsGraph[mutation]) return [];
    const mutationData = finalResultsGraph[mutation];
    return Object.entries(mutationData)
      .map(([treatment, data]) => ({
        treatment,
        general: data[`general_${type.toLowerCase()}` as keyof typeof data] || 0,
        cns: data[`cns_${type.toLowerCase()}` as keyof typeof data] || 0,
      }))
      .sort((a, b) => b.general - a.general);
  }, [finalResultsGraph]);

  const chartData = useMemo(() => {
    if (!hasData || !selectedMutation) return { ORR: [], PFS: [] };
    return {
      ORR: prepareChartData('ORR', selectedMutation),
      PFS: prepareChartData('PFS', selectedMutation),
    };
  }, [selectedMutation, prepareChartData, hasData]);

  const pageCount = (type: 'ORR' | 'PFS') => Math.ceil(chartData[type].length / itemsPerPage);

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
  };

  const CustomXAxisTick = ({ x, y, payload }: any) => {
    const truncatedText = truncateText(payload.value, 25); // Adjust 20 to your preferred max length
    return (
      <g transform={`translate(${x},${y})`}>
        <text 
          x={0} 
          y={0} 
          dy={16} 
          textAnchor="end" 
          fill="#666"
          transform="rotate(-45)"
          style={{ fontSize: '12px' }}
        >
          <title>{payload.value}</title> {/* This creates a native HTML tooltip */}
          {truncatedText}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <p><strong>{label}</strong></p>
          {payload.map((pld: any, index: number) => (
            <p key={index} style={{ color: pld.fill }}>
              {pld.name}: {pld.value}
            </p>
          ))}
        </TooltipContainer>
      );
    }
    return null;
  };

  const renderChart = (type: 'ORR' | 'PFS') => {
    const data = chartData[type].slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    return (
      <>
        <CustomLegend>
          <LegendItem>
            <LegendColor color="#8884d8" />
            General {type}
          </LegendItem>
          <LegendItem>
            <LegendColor color="#82ca9d" />
            CNS {type}
          </LegendItem>
        </CustomLegend>
        <ResponsiveContainer width="100%" height={600}>
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 150 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="treatment" 
              height={120}
              tick={<CustomXAxisTick />}
              interval={0}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="general" fill="#8884d8" name={`General ${type}`} />
            <Bar dataKey="cns" fill="#82ca9d" name={`CNS ${type}`} />
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(0);
  };

  return (
    <Container>
      <ModalTitle>Results in Graphs</ModalTitle>
      <ContentWrapper>
        {hasData ? (
          <>
            <TabContainer>
              {mutations.map((mutation) => (
                <Tab
                  key={mutation}
                  onClick={() => {
                    setSelectedMutation(mutation);
                    setCurrentPage(0);
                  }}
                  active={mutation === selectedMutation}
                >
                  {mutation}
                </Tab>
              ))}
            </TabContainer>
            <ControlsContainer>
              <label htmlFor="itemsPerPage">Items per page:</label>
              <Select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                {[3, 5, 10, 15, 20].map(num => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </Select>
            </ControlsContainer>
            <ChartContainer>
              <ChartTitle>ORR (Objective Response Rate)</ChartTitle>
              {renderChart('ORR')}
              <PaginationContainer>
                <PaginationButton onClick={() => setCurrentPage(p => Math.max(0, p - 1))} disabled={currentPage === 0}>
                  Previous
                </PaginationButton>
                <PageInfo>Page {currentPage + 1} of {pageCount('ORR')}</PageInfo>
                <PaginationButton onClick={() => setCurrentPage(p => Math.min(pageCount('ORR') - 1, p + 1))} disabled={currentPage === pageCount('ORR') - 1}>
                  Next
                </PaginationButton>
              </PaginationContainer>
            </ChartContainer>
            <ChartContainer>
              <ChartTitle>PFS (Progression-Free Survival)</ChartTitle>
              {renderChart('PFS')}
              <PaginationContainer>
                <PaginationButton onClick={() => setCurrentPage(p => Math.max(0, p - 1))} disabled={currentPage === 0}>
                  Previous
                </PaginationButton>
                <PageInfo>Page {currentPage + 1} of {pageCount('PFS')}</PageInfo>
                <PaginationButton onClick={() => setCurrentPage(p => Math.min(pageCount('PFS') - 1, p + 1))} disabled={currentPage === pageCount('PFS') - 1}>
                  Next
                </PaginationButton>
              </PaginationContainer>
            </ChartContainer>
          </>
        ) : (
          <NoDataMessage>No graph results data found.</NoDataMessage>
        )}
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const ModalTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const Tab = styled.button<{ active: boolean }>`
  padding: 10px 15px;
  border: none;
  background-color: ${(props) => (props.active ? '#4CAF50' : '#f1f1f1')};
  color: ${(props) => (props.active ? 'white' : 'black')};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => (props.active ? '#45a049' : '#ddd')};
  }
  margin: 2px;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    padding: 8px 12px;
    font-size: 0.8rem;
  }
`;

const ChartContainer = styled.div`
  margin-bottom: 50px;
  overflow-x: auto;
  padding-bottom: 20px;
`;

const ChartTitle = styled.h3`
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const PaginationButton = styled.button`
  padding: 8px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  font-size: 0.9rem;

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
`;

const PageInfo = styled.span`
  margin: 0 20px;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin: 10px 0;
    width: 100%;
    text-align: center;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const Select = styled.select`
  margin-left: 10px;
  padding: 5px;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    margin-top: 5px;
  }
`;

const NoDataMessage = styled.p`
  text-align: center;
  font-style: italic;
  color: #666;
`;

const CustomLegend = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const LegendColor = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  margin-right: 5px;
`;

const TooltipContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export default GraphResultsModalContent;