import axios, { AxiosError } from 'axios';
import { getToken, logout } from '../utils/authUtils';

const API_URL = process.env.REACT_APP_SERVER_URL;
const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

const api = axios.create({
  baseURL: API_URL,
});

// Add an interceptor to automatically add the token to all requests
api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  // If in production, ensure we're using HTTPS
  if (IS_PRODUCTION && config.url) {
    const url = new URL(config.url, API_URL);
    url.protocol = 'https:';
    url.port = '443';
    config.url = url.toString();
  }
  
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor to handle token invalidation
api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      // Token is invalid or expired
      logout();
    }
    return Promise.reject(error);
  }
);

export const login = async (email: string, password: string) => {
  const response = await api.post('/api/v1/users/login', { email, password }, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  return response.data;
};

export const signup = async (email: string, password: string, organization: string, name: string, profession: string) => {
  const response = await api.post('/api/v1/users/signup', { email, password, organization, name, profession }, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  return response.data;
};

export const getAnalysis = async () => {
  const response = await api.get('/api/v1/analysis');
  return response.data;
};

export const submitAnalysis = async (formData: FormData) => {
  const response = await api.post('/api/v1/analysis', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const deleteAnalysis = async (analysisId: string) => {
  const response = await api.delete(`/api/v1/analysis/${analysisId}`);
  return response.data;
};

export const getUser = async () => {
  try {
    const response = await api.get('/api/v1/users');
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const updateUser = async (userData: any) => {
  try {
    const response = await api.patch('/api/v1/users', userData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};

export const refreshToken = async (): Promise<string> => {
  try {
    const response = await api.post('/api/v1/users/refresh-token');
    return response.data.token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};