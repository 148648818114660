import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import Dashboard from './components/Dashboard/Dashboard';
import { getToken, startTokenRefresh, stopTokenRefresh, resetSessionTimeout, clearSessionTimeout } from './utils/authUtils';

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  return getToken() ? element : <Navigate to="/login" />;
};

const App: React.FC = () => {
  useEffect(() => {
    const token = getToken();
    if (token) {
      startTokenRefresh();
      resetSessionTimeout();
    }

    const handleActivity = () => {
      if (getToken()) {
        resetSessionTimeout();
      }
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    return () => {
      stopTokenRefresh();
      clearSessionTimeout();
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Router>
  );
};

export default App;