import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

interface MedsMutationsModalContentProps {
  finalResults: Record<string, Record<string, string>>;
}

const MedsMutationsModalContent: React.FC<MedsMutationsModalContentProps> = ({ finalResults }) => {
  const [selectedMutation, setSelectedMutation] = useState<string>('');
  const [selectedMed, setSelectedMed] = useState<string>('');
  const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth <= 768);
  const detailsRef = useRef<HTMLDivElement>(null);

  const hasData = finalResults && Object.keys(finalResults).length > 0;

  useEffect(() => {
    if (hasData && !selectedMutation) {
      setSelectedMutation(Object.keys(finalResults)[0]);
    }
  }, [finalResults, hasData, selectedMutation]);

  useEffect(() => {
    setSelectedMed('');
    if (detailsRef.current) {
      detailsRef.current.scrollTop = 0;
    }
  }, [selectedMutation]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMedSelect = (med: string) => {
    setSelectedMed(med);
    if (detailsRef.current) {
      detailsRef.current.scrollTop = 0;
    }
  };

  return (
    <ModalContent>
      <ModalTitle>Meds - Mutations Results</ModalTitle>
      <ContentWrapper>
        {hasData ? (
          <>
            <Header>
              <MutationTabs>
                {Object.keys(finalResults).map((mutation) => (
                  <MutationTab
                    key={mutation}
                    onClick={() => setSelectedMutation(mutation)}
                    isActive={selectedMutation === mutation}
                  >
                    {mutation}
                  </MutationTab>
                ))}
              </MutationTabs>
            </Header>
            <ContentArea isMobileView={isMobileView}>
              <MedsList isMobileView={isMobileView}>
                {selectedMutation &&
                  Object.keys(finalResults[selectedMutation]).map((med) => (
                    <MedItem
                      key={med}
                      onClick={() => handleMedSelect(med)}
                      isActive={selectedMed === med}
                    >
                      {med}
                    </MedItem>
                  ))}
              </MedsList>
              <MedDetails ref={detailsRef} isMobileView={isMobileView}>
                {selectedMed && selectedMutation ? (
                  <>
                    <MedTitle>{selectedMed}</MedTitle>
                    <MedDescription>{finalResults[selectedMutation][selectedMed]}</MedDescription>
                  </>
                ) : (
                  <PlaceholderText>Select a treatment to view details</PlaceholderText>
                )}
              </MedDetails>
            </ContentArea>
          </>
        ) : (
          <NoDataMessage>No meds-mutations data found.</NoDataMessage>
        )}
      </ContentWrapper>
    </ModalContent>
  );
};

const ModalContent = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  text-align: left;
  width: 100%;
`;

const ModalTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const Header = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
`;

const MutationTabs = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 0 20px;
  -webkit-overflow-scrolling: touch;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MutationTab = styled.button<{ isActive: boolean }>`
  padding: 12px 20px;
  border: none;
  background: ${(props) => (props.isActive ? '#4CAF50' : 'transparent')};
  color: ${(props) => (props.isActive ? '#fff' : '#333')};
  cursor: pointer;
  white-space: nowrap;
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  transition: all 0.3s ease;
  
  &:hover {
    background: ${(props) => (props.isActive ? '#45a049' : '#e0e0e0')};
  }
`;

const ContentArea = styled.div<{ isMobileView: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobileView ? 'column' : 'row')};
  flex: 1;
  overflow: hidden;
  background-color: #ffffff;
`;

const MedsList = styled.div<{ isMobileView: boolean }>`
  width: ${(props) => (props.isMobileView ? '100%' : '250px')};
  border-right: ${(props) => (props.isMobileView ? 'none' : '1px solid #e0e0e0')};
  border-bottom: ${(props) => (props.isMobileView ? '1px solid #e0e0e0' : 'none')};
  overflow-y: auto;
  max-height: ${(props) => (props.isMobileView ? '200px' : 'calc(80vh - 50px)')};
`;

const MedItem = styled.div<{ isActive: boolean }>`
  padding: 12px 20px;
  cursor: pointer;
  background: ${(props) => (props.isActive ? '#e8f5e9' : 'transparent')};
  color: ${(props) => (props.isActive ? '#4CAF50' : '#333')};
  border-left: 4px solid ${(props) => (props.isActive ? '#4CAF50' : 'transparent')};
  transition: all 0.3s ease;
  
  &:hover {
    background: #f0f0f0;
  }
`;

const MedDetails = styled.div<{ isMobileView: boolean }>`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  max-height: ${(props) => (props.isMobileView ? 'calc(60vh - 50px)' : 'calc(80vh - 50px)')};
`;

const MedTitle = styled.h3`
  color: #2196F3;
  margin-bottom: 15px;
  font-size: 1.2rem;
`;

const MedDescription = styled.p`
  color: #333;
  line-height: 1.6;
`;

const PlaceholderText = styled.p`
  color: #757575;
  font-style: italic;
`;

const NoDataMessage = styled.p`
  text-align: center;
  font-style: italic;
  color: #666;
`;

export default MedsMutationsModalContent;