import { refreshToken } from '../services/api';

let refreshInterval: NodeJS.Timeout | null = null;
let sessionTimeout: NodeJS.Timeout | null = null;

export const setToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const getToken = (): string | null => {
  return localStorage.getItem('token');
};

export const removeToken = () => {
  localStorage.removeItem('token');
};

export const startTokenRefresh = () => {
  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
  refreshInterval = setInterval(async () => {
    try {
      const newToken = await refreshToken();
      setToken(newToken);
    } catch (error) {
      console.error('Failed to refresh token:', error);
      logout();
    }
  }, 15 * 60 * 1000); // Refresh every 15 minutes
};

export const stopTokenRefresh = () => {
  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
};

export const resetSessionTimeout = () => {
  if (sessionTimeout) {
    clearTimeout(sessionTimeout);
  }
  sessionTimeout = setTimeout(() => {
    logout();
  }, 30 * 60 * 1000); // Log out after 30 minutes of inactivity
};

export const clearSessionTimeout = () => {
  if (sessionTimeout) {
    clearTimeout(sessionTimeout);
  }
};

export const logout = () => {
  removeToken();
  stopTokenRefresh();
  clearSessionTimeout();
  window.location.href = '/login';
};