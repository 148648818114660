import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { getAnalysis, submitAnalysis, deleteAnalysis, getUser, updateUser } from '../../services/api';
import { getToken, removeToken } from '../../utils/authUtils';
import AnalysisCard from '../AnalysisCard/AnalysisCard';
import Modal from '../Modal/Modal';
import AnalysisForm from '../AnalysisForm/AnalysisForm';
import ProfileForm from '../ProfileForm/ProfileForm';
import DisclaimerModal from '../DisclaimerModal/DisclaimerModal';
import logoImage from '../../assets/logo.png';

const Dashboard: React.FC = () => {
  const [analyses, setAnalyses] = useState<any[]>([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const [inputType, setInputType] = useState<'file' | 'text'>('file');
  const [file, setFile] = useState<File | null>(null);
  const [text, setText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [mostRecentAnalysisId, setMostRecentAnalysisId] = useState<string | null>(null);
  const [isDisclaimerConsentRequired, setIsDisclaimerConsentRequired] = useState(false);
  const navigate = useNavigate();
  const effectRan = useRef(false);
  
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [greeting, setGreeting] = useState('');

  const showSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarVisible(true);
    setTimeout(() => setSnackbarVisible(false), 3000);
  };

  const fetchAnalyses = useCallback(async () => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }
    try {
      setIsLoading(true);
      const data = await getAnalysis();
      setAnalyses(data);
      
      if (data.length > 0) {
        const sortedAnalyses = [...data].sort((a, b) => 
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setMostRecentAnalysisId(sortedAnalyses[0]._id);
      } else {
        setMostRecentAnalysisId(null);
      }
    } catch (err) {
      setError('Error fetching analyses');
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  const fetchUserData = useCallback(async () => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }
    try {
      const data = await getUser();
      setUserData(data);
      setIsDisclaimerConsentRequired(!data.hasConsentForDisclaimer);
    } catch (err) {
      showSnackbar('Error fetching user data');
    }
  }, [navigate]);

  useEffect(() => {
    if (effectRan.current) return;
    fetchAnalyses();
    fetchUserData();
    effectRan.current = true;
  }, [fetchAnalyses, fetchUserData]);

  useEffect(() => {
    const updateGreeting = () => {
      const hour = new Date().getHours();
      let greetingText = '';
      if (hour >= 5 && hour < 12) greetingText = 'Good morning';
      else if (hour >= 12 && hour < 18) greetingText = 'Good afternoon';
      else if (hour >= 18 && hour < 22) greetingText = 'Good evening';
      else greetingText = 'Good night';
      
      setGreeting(`${greetingText}, ${userData?.name || 'User'}`);
    };

    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [userData]);

  const handleLogout = () => {
    removeToken();
    navigate('/login');
  };

  const handleRunAnalysis = () => {
    setIsModalOpen(true);
  };

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleDisclaimerClick = () => {
    setIsDisclaimerModalOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    const formData = new FormData();
    if (inputType === 'file' && file) {
      formData.append('file', file);
    } else if (inputType === 'text') {
      formData.append('text', text);
    }

    try {
      await submitAnalysis(formData);
      setIsModalOpen(false);
      showSnackbar('Analysis in progress...');
      fetchAnalyses();
    } catch (error) {
      console.error('Error submitting analysis:', error);
      showSnackbar('Error submitting analysis');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteAnalysis = async (analysisId: string) => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await deleteAnalysis(analysisId);
      showSnackbar('Analysis deleted successfully');
      fetchAnalyses();
    } catch (error) {
      console.error('Error deleting analysis:', error);
      showSnackbar('Error deleting analysis');
    }
  };

  const handleProfileUpdate = async (updatedData: any) => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }
    try {
      await updateUser(updatedData);
      setUserData({ ...userData, ...updatedData });
      setIsProfileModalOpen(false);
      showSnackbar('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      showSnackbar('Error updating profile');
    }
  };

  const handleDisclaimerConsent = async () => {
    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }
    try {
      await updateUser({ hasConsentForDisclaimer: true });
      setIsDisclaimerConsentRequired(false);
      setUserData((prevData: any) => ({ ...prevData, hasConsentForDisclaimer: true }));
      showSnackbar('Consent updated successfully');
    } catch (error) {
      console.error('Error updating consent:', error);
      showSnackbar('Error updating consent');
    }
  };

  return (
    <DashboardContainer>
      {isDisclaimerConsentRequired && (
        <DisclaimerModal
          isOpen={true}
          onClose={() => {}}
          onConsent={handleDisclaimerConsent}
          isConsentRequired={true}
        />
      )}
      <DashboardHeader>
        <LogoImage src={logoImage} alt="Logo" />
        <GreetingMessage>{greeting}</GreetingMessage>
        <ButtonContainer>
          <RunAnalysisButton onClick={handleRunAnalysis}>Run Analysis</RunAnalysisButton>
          <ProfileButton onClick={handleProfileClick}>My Profile</ProfileButton>
          <DisclaimerButton onClick={handleDisclaimerClick}>Disclaimer</DisclaimerButton>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </ButtonContainer>
      </DashboardHeader>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <AnalysisGridContainer>
        {isLoading ? (
          <SpinnerContainer>
            <CircularProgress style={{ color: '#2196F3' }} />
          </SpinnerContainer>
        ) : (
          <AnalysisGrid>
            {analyses.map((analysis) => (
              <AnalysisCard 
                key={analysis._id} 
                analysis={analysis} 
                onDelete={handleDeleteAnalysis}
                isMostRecent={analysis._id === mostRecentAnalysisId}
              />
            ))}
          </AnalysisGrid>
        )}
      </AnalysisGridContainer>
      {!isLoading && analyses.length === 0 && !error && (
        <NoAnalysesMessage>No analyses found. Your recent analyses will appear here.</NoAnalysesMessage>
      )}

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AnalysisForm
          inputType={inputType}
          setInputType={setInputType}
          file={file}
          setFile={setFile}
          text={text}
          setText={setText}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
        />
      </Modal>

      <Modal isOpen={isProfileModalOpen} onClose={() => setIsProfileModalOpen(false)}>
        {userData && (
          <ProfileForm
            userData={userData}
            onSubmit={handleProfileUpdate}
          />
        )}
      </Modal>

      <DisclaimerModal
        isOpen={isDisclaimerModalOpen}
        onClose={() => setIsDisclaimerModalOpen(false)}
        onConsent={() => {}}
        isConsentRequired={false}
      />

      <SnackbarContainer isVisible={snackbarVisible}>
        {snackbarMessage}
      </SnackbarContainer>
    </DashboardContainer>
  );
};

const LogoImage = styled.img`
  width: 300px;
  height: auto;
  max-width: 100%;
  margin-bottom: 5px;
`;

const DashboardContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const DashboardHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    flex: 1 1 40%;
    margin-bottom: 10px;
  }
`;

const LogoutButton = styled(Button)`
  background-color: #f44336;
`;

const RunAnalysisButton = styled(Button)`
  background-color: #4CAF50;
`;

const ProfileButton = styled(Button)`
  background-color: #2196F3;
`;

const DisclaimerButton = styled(Button)`
  background-color: #FF9800;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  text-align: center;
  margin-bottom: 20px;
`;

const AnalysisGridContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AnalysisGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: 350px;
  }
`;

const NoAnalysesMessage = styled.p`
  text-align: center;
  color: #666;
  margin-top: 40px;
`;

const SnackbarContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border-radius: 4px;
  z-index: 1000;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: ${props => props.isVisible ? 1 : 0};
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  max-width: 90%;
  text-align: center;
`;

const GreetingMessage = styled.h2`
  font-size: 1.2rem;
  color: #333;
  margin: 0;
  text-align: center;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export default Dashboard;