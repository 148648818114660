import React from 'react';
import styled from 'styled-components';
import { usePDF } from 'react-to-pdf';

interface SummaryModalContentProps {
  summary: string;
}

const SummaryModalContent: React.FC<SummaryModalContentProps> = ({ summary }) => {
  const formattedSummary = summary ? formatSummary(summary) : null;
  const { toPDF, targetRef } = usePDF({filename: 'analysis_summary.pdf'});

  return (
    <ModalContent>
      <ModalTitle>Analysis Summary</ModalTitle>
      {summary && (
        <DownloadButton onClick={() => toPDF()}>Download as PDF</DownloadButton>
      )}
      <div ref={targetRef}>
        <SummarySection>
          {formattedSummary || <NoDataMessage>No summary data found.</NoDataMessage>}
        </SummarySection>
      </div>
    </ModalContent>
  );
};

const formatSummary = (summaryText: string) => {
  const sections = summaryText.split('###').filter(s => s.trim());
  
  return sections.map((section, index) => {
    const [title, ...content] = section.split('\n').filter(s => s.trim());
    
    const formattedContent = content.map((line, lineIndex) => {
      line = line.replace(/\*/g, '');
      
      if (line.startsWith('-')) {
        return <SummaryListItem key={lineIndex}>{line.substring(1).trim()}</SummaryListItem>;
      } else if (line.match(/^\d+\./)) {
        return (
          <SummaryListItem key={lineIndex}>
            <strong>{line.split(':')[0]}:</strong> {line.split(':').slice(1).join(':').trim()}
          </SummaryListItem>
        );
      } else {
        return <p key={lineIndex}>{line.trim()}</p>;
      }
    });

    return (
      <div key={index}>
        <SummaryTitle>{title.trim()}</SummaryTitle>
        <SummaryList>{formattedContent}</SummaryList>
      </div>
    );
  });
};

const ModalContent = styled.div`
  text-align: center;
`;

const ModalTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const SummarySection = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  text-align: left;
`;

const SummaryTitle = styled.h3`
  margin-bottom: 15px;
`;

const SummaryList = styled.ul`
  padding-left: 20px;
`;

const SummaryListItem = styled.li`
  margin-bottom: 10px;
`;

const DownloadButton = styled.button`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 4px;
`;

const NoDataMessage = styled.p`
  text-align: center;
  font-style: italic;
  color: #666;
`;

export default SummaryModalContent;