import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal/Modal';
import DetailsModalContent from '../DetailsModalContent/DetailsModalContent';
import SummaryModalContent from '../SummaryModalContent/SummaryModalContent';
import MedsMutationsModalContent from '../MedsMutationsModalContent/MedsMutationsModalContent';
import GraphResultsModalContent from '../GraphResultsModalContent/GraphResultsModalContent';
import { CircularProgress } from '@mui/material';

interface AnalysisCardProps {
  analysis: any;
  onDelete: (analysisId: string) => void;
  isMostRecent: boolean;
}

const AnalysisCard: React.FC<AnalysisCardProps> = ({ analysis, onDelete, isMostRecent }) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const [isMedsMutationsModalOpen, setIsMedsMutationsModalOpen] = useState(false);
  const [isGraphResultsModalOpen, setIsGraphResultsModalOpen] = useState(false);

  const analysisCreatedAt = new Date(analysis.createdAt);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  const analysisCreatedAtformatted = analysisCreatedAt
    .toLocaleString('en-US', options)
    .replace(',', '');

  const handleDelete = () => {
    onDelete(analysis._id);
  };

  const isProcessing = analysis.temp === true;

  return (
    <CardContainer>
      <DeleteButton onClick={handleDelete}>X</DeleteButton>
      <Card>
        {isMostRecent && <MostRecentLabel>Most Recent Analysis</MostRecentLabel>}
        <ContentWrapper>
          <Title>{analysisCreatedAtformatted}</Title>
          {!isProcessing && <Title>Analysis ID: {analysis._id}</Title>}
          
          {isProcessing ? (
            <ProcessingContent>
              <ProcessingMessage>Analysis in progress</ProcessingMessage>
              <CircularProgress size="3rem" />
              {analysis.tempText && <TempText>{analysis.tempText}</TempText>}
            </ProcessingContent>
          ) : (
            <AnalysisContent>
              <SubTitle>Mutations:</SubTitle>
              <List>
                {analysis.mutationsAndPhrases.map((item: any, index: number) => (
                  <li key={index}>{item.base.join(', ').replace(/,\s*$/, '')}</li>
                ))}
              </List>
            </AnalysisContent>
          )}
        </ContentWrapper>
        <ButtonsWrapper>
          <FullWidthButton onClick={() => setIsGraphResultsModalOpen(true)} disabled={isProcessing}>
            Results in Graphs
          </FullWidthButton>
          <ButtonContainer>
            <ActionButton onClick={() => setIsDetailsModalOpen(true)} disabled={isProcessing}>
              Results
            </ActionButton>
            <ActionButton onClick={() => setIsMedsMutationsModalOpen(true)} disabled={isProcessing}>
              Meds - Mutations
            </ActionButton>
            <ActionButton onClick={() => setIsSummaryModalOpen(true)} disabled={isProcessing}>
              Brief
            </ActionButton>
          </ButtonContainer>
        </ButtonsWrapper>
      </Card>

      {!isProcessing && (
        <>
          <Modal isOpen={isDetailsModalOpen} onClose={() => setIsDetailsModalOpen(false)} wide>
            <DetailsModalContent analysisResults={analysis.analysisResults} />
          </Modal>

          <Modal isOpen={isSummaryModalOpen} onClose={() => setIsSummaryModalOpen(false)}>
            <SummaryModalContent summary={analysis.finalReport} />
          </Modal>

          <Modal isOpen={isMedsMutationsModalOpen} onClose={() => setIsMedsMutationsModalOpen(false)} wide>
            <MedsMutationsModalContent finalResults={analysis.finalResults} />
          </Modal>

          <Modal isOpen={isGraphResultsModalOpen} onClose={() => setIsGraphResultsModalOpen(false)} wide>
            <GraphResultsModalContent finalResultsGraph={analysis.finalResultsGraph} />
          </Modal>
        </>
      )}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  position: relative;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 14px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px; // Set a fixed height
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

const SubTitle = styled.h4`
  margin-top: 15px;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
`;

const List = styled.ul`
  padding-left: 0;
  list-style-position: inside;
  width: 100%;
  text-align: center;
  margin: 0;
  overflow-y: auto;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;

const ActionButton = styled.button`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const FullWidthButton = styled(ActionButton)`
  width: 100%;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
`;

const MostRecentLabel = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  color: red;
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 5px;
`;

const ProcessingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ProcessingMessage = styled.p`
  color: #ff9800;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
`;

const TempText = styled.p`
  margin-top: 10px;
  text-align: center;
  font-style: italic;
`;

const AnalysisContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

export default AnalysisCard;