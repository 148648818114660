import React from 'react';
import styled from 'styled-components';

interface AnalysisFormProps {
  inputType: 'file' | 'text';
  setInputType: (type: 'file' | 'text') => void;
  file: File | null;
  setFile: (file: File | null) => void;
  text: string;
  setText: (text: string) => void;
  isSubmitting: boolean;
  onSubmit: (e: React.FormEvent) => void;
}

const AnalysisForm: React.FC<AnalysisFormProps> = ({
  inputType,
  setInputType,
  file,
  setFile,
  text,
  setText,
  isSubmitting,
  onSubmit,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <CenteredHeading>Run New Analysis</CenteredHeading>
      <RadioGroup>
        <label>
          <input
            type="radio"
            value="file"
            checked={inputType === 'file'}
            onChange={() => setInputType('file')}
          />
          Upload File
        </label>
        <label>
          <input
            type="radio"
            value="text"
            checked={inputType === 'text'}
            onChange={() => setInputType('text')}
          />
          Enter Text
        </label>
      </RadioGroup>

      <InputContainer>
        {inputType === 'file' ? (
          <>
            <p>Upload NGS (Mutations analysis, e.g: Oncotest, Guardant360 etc..)</p>
            <FileInput
              type="file"
              onChange={(e) => setFile(e.target.files?.[0] || null)}
            />
          </>
        ) : (
          <>
            <p>Enter mutations discovered by NGS (Mutations analysis, e.g: Oncotest, Guardant360 etc..)</p>
            <TextArea
              placeholder="e.g: egfr,exon 20,V769_D770insASV"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </>
        )}
      </InputContainer>

      <SubmitButton type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </SubmitButton>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const CenteredHeading = styled.h2`
  text-align: center;
  width: 100%;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 20px;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const FileInput = styled.input`
  margin-top: 10px;
  width: 100%;
`;

const TextArea = styled.textarea`
  margin-top: 10px;
  height: 100px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
`;

const SubmitButton = styled.button`
  background-color: #4CAF50;
  width: fit-content;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default AnalysisForm;