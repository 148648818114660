import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal/Modal';
import { disclaimerContent } from '../../constants/disclaimerContent';

interface DisclaimerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConsent: () => void;
  isConsentRequired: boolean;
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({ 
  isOpen, 
  onClose, 
  onConsent, 
  isConsentRequired 
}) => {
  const [hasConsented, setHasConsented] = useState(false);

  const handleConsent = () => {
    if (hasConsented) {
      onConsent();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={isConsentRequired ? () => {} : onClose}>
      <DisclaimerContent dangerouslySetInnerHTML={{ __html: disclaimerContent }} />
      {isConsentRequired && (
        <ConsentSection>
          <label>
            <input
              type="checkbox"
              checked={hasConsented}
              onChange={(e) => setHasConsented(e.target.checked)}
            />
            I have read and agree to the disclaimer
          </label>
          <ConsentButton onClick={handleConsent} disabled={!hasConsented}>
            Consent and Continue
          </ConsentButton>
        </ConsentSection>
      )}
    </Modal>
  );
};

const DisclaimerContent = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  padding: 20px;
  line-height: 1.6;

  h2 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 15px;
  }

  ol {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }

  strong {
    font-weight: bold;
  }
`;

const ConsentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const ConsentButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default DisclaimerModal;