import React from 'react';
import styled from 'styled-components';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  wide?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, wide = false }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()} wide={wide}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ModalScrollContent>
          {children}
        </ModalScrollContent>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div<{ wide: boolean }>`
  background-color: white;
  border-radius: 8px;
  width: 95%;
  max-width: ${props => props.wide ? '1200px' : '600px'};
  max-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.wide ? '1300px' : '700px'}) {
    width: 98%;
  }
`;

const ModalScrollContent = styled.div`
  padding: 20px;
  overflow-y: auto;
  max-height: calc(90vh - 40px); // Adjust based on padding
  
  @media (max-width: 700px) {
    padding: 15px;
  }
`;

const CloseButton = styled.button`
  position: sticky;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  z-index: 1001;
  padding: 5px;
  line-height: 1;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  align-self: flex-end;
  margin: 10px 10px -40px 0; // Negative bottom margin to overlap with content

  &:hover {
    opacity: 1;
  }
`;

export default Modal;