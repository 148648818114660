export const disclaimerContent = `
<h2>Disclaimer for OncoHunter</h2>

<p>The information provided by OncoHunter (the "Service") is intended for informational purposes only. This Service aggregates publicly available information regarding potential treatments, therapies, or medicines that may have been associated with specific genetic mutations or alterations, as entered by the user. The Service does not provide medical advice, diagnosis, or treatment.</p>

<ol>
  <li><strong>Not a Substitute for Professional Medical Advice:</strong> The content available through the OncoHunter Service is <strong>NOT</strong> a substitute for professional healthcare advice, diagnosis, or treatment. Users should always seek the advice of a qualified healthcare provider or oncologist with any questions regarding a medical condition or treatment. Never disregard professional medical advice or delay seeking treatment based on the information provided by this Service.</li>

  <li><strong>No Warranties or Guarantees:</strong> OncoHunter does <strong>NOT</strong> guarantee the accuracy, completeness, or usefulness of any information obtained through this Service. The content provided may contain outdated or incomplete data and may not reflect the most current medical research, standards of care, or treatment options.</li>

  <li><strong>No Responsibility for Actions Taken:</strong> Any action taken by users based on the information presented by OncoHunter is <strong>at their own risk</strong>. OncoHunter and its operators, owners, employees, and partners will not be held liable for any decision, action, or inaction taken by users in relation to the content provided by the Service. This includes but is not limited to, any medical treatment, care, or decision-making based on the aggregated information.</li>

  <li><strong>No Medical Professional-Patient Relationship:</strong> The use of this Service does not create a doctor-patient relationship or any other healthcare professional relationship between the user and OncoHunter. This Service is not intended to replace the need for consultations with qualified healthcare professionals.</li>

  <li><strong>No Liability for External Information:</strong> OncoHunter relies on external sources of publicly available information for the aggregation of content. The Service does <strong>NOT</strong> endorse or verify the accuracy of external content and bears no responsibility for the correctness, legality, or any other aspect of the sources from which the information is gathered.</li>

  <li><strong>Consultation with Healthcare Providers:</strong> Users of OncoHunter should always consult with qualified healthcare providers, including oncologists, before making any healthcare decisions. The information provided by the Service is for general informational purposes only and should be reviewed and validated by medical professionals.</li>

  <li><strong>Limitation of Liability:</strong> To the fullest extent permitted by law, OncoHunter and its operators, owners, employees, and partners disclaim any and all liability for any direct, indirect, incidental, consequential, special, or punitive damages arising from or related to the use of this Service or the information presented therein, whether based on breach of contract, tort (including negligence), or otherwise.</li>
</ol>

<p>By using OncoHunter, you acknowledge that you have read, understood, and agreed to this disclaimer in its entirety. If you do not agree to these terms, please refrain from using the Service.</p>
`;